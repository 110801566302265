import {_urlAdvertisement, _urlCallRequest, request} from "../api";
import {ADVERTISEMENT_CONSTS, UTIL_CONSTS,} from "../constants";

export const GetAdvertisement = () => {
    const requestData = {
        url: `${_urlAdvertisement}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: ADVERTISEMENT_CONSTS.GET_ADVERTISEMENT,
                    payload: data
                })
                return data
            })
    };
};

export const SendAdvertisementCallRequest = (data) => {
    const requestData = {
        url: `${_urlAdvertisement}/call-request`,
        method: "POST",
        data
    };
    return dispatch => {
        return request(requestData).then(res=>{
            return res.data
        }).catch(err=>{
            console.log('err', err);
            return null
        })
    }
};
