import {ADVERTISEMENT_CONSTS} from "../constants";

export const initialState = {
    advertisementData: null,
    advertisementShow: null,
};

export default (state = initialState, action) => {

    switch (action.type) {
        case ADVERTISEMENT_CONSTS.GET_ADVERTISEMENT:
            return {
                ...state,
                advertisementData: action.payload,
            };

        default:
            return state;
    }
}
