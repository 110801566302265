import {combineReducers} from "redux";

// import reducers
import general from './general';
import slider from './slider';
import about from './about';
import contact from './contact';
import buildings from "./buildings";
import utils from "./utils";
import areas from "./areas";
import advertisement from "./advertisement";

export default combineReducers({
    general,
    slider,
    about,
    contact,
    buildings,
    utils,
    areas,
    advertisement,
});
