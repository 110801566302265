// import packages
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Modal} from 'antd';
import {isEmail} from "validator";

// Import assets
import "./reserve-modal.scss"

// Import Components
import {InputGroup} from "../../ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ReserveHouse} from "../../../redux/actions";
import {RESERVE_MODAL_TITLE_TYPES} from "../../../constants/constants";


function ReserveModal(props) {
    const {area, staticTexts} = props
    const [fieldsData, setFieldsData] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
    })
    const [errors, setErrors] = useState({
        fullName: "",
        email: "",
        phoneNumber: "",
    })
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [modalTitleType, setModalTitleType] = useState("");
    const confirmReserve = () => {

        if (validate()) {
            setConfirmLoading(true);
            const reqData = {
                ...fieldsData
            }

            reqData.area = area?.id
            props.ReserveHouse(reqData).then(() => {
                setTimeout(() => {
                    setConfirmLoading(false);
                    setModalTitleType(RESERVE_MODAL_TITLE_TYPES.success);
                    setConfirmBtnDisable(true)
                }, 2000)
                setTimeout(() => {
                    // setModalTitleType(RESERVE_MODAL_TITLE_TYPES.title);
                    // setConfirmBtnDisable(false)
                }, 7000)
            }).catch((err) => {
                if (err.response?.data?.slug === "email") {
                    setConfirmLoading(false);
                    setErrors({
                        ...errors,
                        email: true
                    })
                } else {
                    setTimeout(() => {
                        setConfirmLoading(false);
                        setConfirmBtnDisable(true)
                        setModalTitleType(RESERVE_MODAL_TITLE_TYPES.error);
                    }, 2000)
                    setTimeout(() => {
                    }, 7000)
                }
            })
        }
    };
    const cancelReserve = () => {
        closeModal()
    };
    const closeModal = () => {
        props.toggleVisibility()
        setFieldsData({
            fullName: "",
            email: "",
            phoneNumber: "",
        })
        setErrors({})
        setModalTitleType(RESERVE_MODAL_TITLE_TYPES.title);
        setConfirmBtnDisable(false)
    };

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email) || (key === 'phoneNumber' && fieldsData[key]?.length < 8))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    }

    const text = modalTitleType === RESERVE_MODAL_TITLE_TYPES.success
        ? staticTexts?.modal_success_message : (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error
            ? staticTexts?.modal_error_message : (area?.floor?.number > 2 ? staticTexts?.modal_title_house :
                (area?.floor?.number > 0 && area?.floor?.number <= 2 ? staticTexts?.modal_title_office : staticTexts?.modal_title_parking)))
    return <Modal
        title={text}
        className={`reservation-modal ${modalTitleType === RESERVE_MODAL_TITLE_TYPES.success ? "success" :
            (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error ? "error" : "")} ${confirmBtnDisable ? "confirm-btn-disable" : ""}`}
        centered
        cancelText={confirmBtnDisable ? staticTexts?.modal_btn_close : staticTexts?.modal_btn_cancel}
        okText={staticTexts?.modal_btn_save}
        visible={props.visible}
        zIndex={10001}
        onOk={!confirmBtnDisable && confirmReserve}
        confirmLoading={confirmLoading}
        onCancel={cancelReserve}>
        <InputGroup
            inputType={'input'}
            type={"text"}
            value={fieldsData.fullName}
            error={errors.fullName}
            name={'fullName'}
            maxLength={100}
            label={staticTexts?.modal_name_label}
            onChange={getInputValues}
        />
        <InputGroup
            inputType={'input'}
            type={"text"}
            value={fieldsData.email}
            error={errors.email}
            name={'email'}
            maxLength={50}
            label={staticTexts?.modal_email_label}
            onChange={getInputValues}
        />
        <InputGroup
            inputType={'input'}
            type={"text"}
            value={fieldsData.phoneNumber}
            error={errors.phoneNumber}
            name={'phoneNumber'}
            maxLength={12}
            label={staticTexts?.modal_phone_label}
            onChange={getInputValues}
        />
        <InputGroup
            inputType={'input'}
            type={"text"}
            disabled
            value={`${area?.areaNumber ? "N" + area?.areaNumber : (area?.parkingNumber ? "N" + area?.parkingNumber : "")}`}
            label={staticTexts?.modal_house_number_label}
        />

    </Modal>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
]);

const mapDispatchToProps = {
    ReserveHouse
};

export default connect(mapStateToProps, mapDispatchToProps)(ReserveModal);