import {initialState as general} from "../reducers/general";
import {initialState as slider} from "../reducers/slider";
import {initialState as about} from "../reducers/about";
import {initialState as contact} from "../reducers/contact";
import {initialState as buildings} from "../reducers/buildings";
import {initialState as utils} from "../reducers/utils";
import {initialState as areas} from "../reducers/areas";
import {initialState as advertisement} from "../reducers/advertisement";

const reducers = {
    general,
    slider,
    about,
    contact,
    buildings,
    utils,
    areas,
    advertisement,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state?.[selectorKey]?.[key];
        });
    });
    return sData;
};





