// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Modal,} from 'antd';

// Import assets
import "./advertisement-modal.scss"
import {LoadingOutlined} from '@ant-design/icons'

// Import Components

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetAdvertisement, SendAdvertisementCallRequest} from "../../../redux/actions";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import {InputGroup} from "../../ui-elements/input-group/inputGroup";


function AdvertisementModal(props) {
    const {staticTexts} = props
    const [advertisementData, setAdvertisementData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [sendAdState, setSendAdState] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const phoneRef = useRef('');


    useEffect(() => {
        const advertisementSeen = JSON.parse(sessionStorage.getItem("advertisementSeen"));
        console.log('advertisementSeen', advertisementSeen);
        if (!advertisementSeen) {
            getAdvertisementData()
        }
    }, [])

    async function getAdvertisementData() {
        const adData = await props.GetAdvertisement();
        console.log('adData', adData)
        if (adData && !adData.isHidden) {
            setAdvertisementData(adData);
            setVisible(true)
        }
    }

    function onClick() {
        if (advertisementData?.url) {
            window.open(advertisementData?.url, '_blank');
            closeModal();
        } else {
            if (!phoneNumber || phoneNumber.length !== 12) {
                setPhoneNumberError(true);
            } else {
                if (sendAdState) {
                    return;
                }
                const reqData = {
                    phoneNumber: phoneNumber,
                }
                setSendAdState('loading')
                props.SendAdvertisementCallRequest(reqData).then(() => {
                    setTimeout(() => {
                        setSendAdState('success')
                        setTimeout(() => {
                            closeModal()
                        }, 3000)
                    }, 1000)
                })

            }

        }
    }

    function getInputValues(e) {
        let {value} = e.target;
        if (value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (phoneNumber.length - value.length !== 1) {
            if (value &&
                !value.startsWith("+374") &&
                value !== '+' &&
                value !== '+3' &&
                value !== '+37' &&
                value !== '+374') {

                value = "+374" + value.replace("+", "");
            }
        }
        if (value) {
            value = value.slice(0, 12)
        }


        phoneRef.current = phoneNumber;
        setPhoneNumber(value)
        phoneNumberError && setPhoneNumberError(false)
    }

    const closeModal = () => {
        sessionStorage.setItem("advertisementSeen", JSON.stringify(true));
        setVisible(false)
    };

    return <Modal
        className={`advertisement-modal`}
        centered={true}
        okText={staticTexts?.modal_btn_close}
        visible={visible}
        zIndex={10001}
        // onOk={()=>{}}
        // confirmLoading={confirmLoading}
        onCancel={closeModal}
    >

        <div className="content">
            {
                advertisementData?.mediaMain?.path &&
                <img className={'offer-img'} src={generateFileMediaUrl(advertisementData?.mediaMain?.path)} alt=""/>
            }

            <div className={'offer-title'}>{advertisementData?.title}</div>
            {/*<pre className={'offer-description'}>{advertisementData?.description}</pre>*/}
            <div className={'offer-description'} dangerouslySetInnerHTML={{__html: advertisementData?.description}}/>
            <div className={'modal-actions'}>
                {!advertisementData?.url && <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={phoneNumber}
                    error={true}
                    name={'phoneNumber'}
                    maxLength={12}
                    classNameOverlay={phoneNumberError && 'invalid'}
                    disabled={sendAdState}
                    label={staticTexts?.ad_modal_phone_label}
                    onChange={getInputValues}
                />}
                {advertisementData?.url ?
                    <button className={'offer-btn'} onClick={onClick}>{staticTexts?.ad_modal_btn_follow_the_link}</button> :
                    <button className={`offer-btn ${sendAdState === 'success' ? 'bg-green' : ''}`}
                            onClick={onClick}>
                            {sendAdState === 'loading' && <LoadingOutlined/>}
                            {sendAdState === 'success' && staticTexts?.ad_modal_btn_send_success_text }
                            {!sendAdState && staticTexts?.ad_modal_btn_send_phone_text}

                    </button>}
            </div>
        </div>

    </Modal>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'advertisementData',
]);

const mapDispatchToProps = {
    GetAdvertisement,
    SendAdvertisementCallRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementModal);